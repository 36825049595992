.footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid silver;
    padding: 0.2rem;
    color: #ccc;
    align-items: center;
    text-align: center;
}

.footer a {
    color: gray;
}

.maxheight {
    min-height: 100vh;
}