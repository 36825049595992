.align-row-center {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    /* Optional: Adjust spacing */
    align-items: normal;
    /* Optional: Align items vertically */
}

.align-row-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.align-row-evenly {
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    flex-wrap: wrap;
}

.align-row input,
.align-row img,
.align-row a,
.align-row input {
    vertical-align: middle;
    /* Aligns the input and image to the middle of the line */
    /* margin-right: 10px; */
    /* Optional: Add spacing between the elements */
}

.align-row {
    display: flex;
    align-items: center;
}

.align-left {
    text-align: left;
    /* Align text and inline elements to the left */
}

.align-row-right {
    text-align: right;
    background-color: antiquewhite;
    display: flex;
    align-items: center;
    align-content: center;
}

/* .row-container {
    display: flex;
    flex-wrap: wrap; */
/* Allows the items to wrap to the next line if needed */
/* gap: 10px; */
/* Optional: Adds space between items */
/* } */

.align-col {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* Optional: Adds space between items */
    /* border: 1px solid red; */
    /* width: fit-content;
    max-width: fit-content; */
    align-items: center;
}

.row-container div {
    background-color: lightblue;
    width: min-content;
    /* Width for each item */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* Optional: Rounded corners */
}