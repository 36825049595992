@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

:root {
    --primary-color: #3498db;
    --border-radius: 10px;
    --padding-size: 8px;
    --color-dark: darkslategray;
    /* #0069d1; */
    --color-light: #fff;
    --color-text: #333;
    --color-blue: #005db8;
}


.cursorPointer:hover {
    font-weight: bold;
    cursor: pointer;
}


.containerCell {
    background-color: white;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 1rem;
    border: 1px solid silver;
    border-radius: 5px;
    display: inline-block;
    /* width: max-content; */

    justify-content: left;
    text-align: left;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */

    /* max-width: 75%; */
}

.input-field,
.textarea-field {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    /* Ensures padding and border are included in the width */
}

.textarea-field {
    min-height: 10rem;
}


form {
    text-align: left;
    margin: 0;
    padding: 0;
}

.containerRowSides {
    display: flex;
    justify-content: space-between;
    /* Optional: Adjust spacing */
    align-items: center;
    /* Optional: Align items vertically */
}

input[type="text"] {
    /* Add your styles here */
    border-radius: 0.3rem;
    border: 1px solid gray;
    padding: 5px;
}

hr {
    /* border-color: silver; */
    border: none;
    border-top: 1px solid silver;
}

.hover:hover {
    background: rgb(230, 230, 230);
    cursor: pointer;
}

.hovered {
    background: rgb(230, 230, 230);
}

/* .hovered:hover {
    background: rgb(195, 195, 195);
} */

.content-box {
    /* display: inline-block; */
    /* width: auto; */
    /* padding: 10px; */
    /* background-color: lightblue; */
    /* border: 1px solid blue; */
    /* align-content: center; */
}

a {
    color: var(--color-blue);
}

table {
    width: 100%;
    border-collapse: collapse;
    /* Ensures borders collapse into a single line */
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}


th,
td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    /* Light gray border for table cells */
}

th {
    background-color: silver;
    /* Light gray background for header */
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
    /* Zebra stripes for table rows */
}

tr:hover {
    background-color: #f1f1f1;
    /* Slightly darker gray when hovering over a row */
}

.bold {
    font-weight: bold;
}