.result-modal {
    border: 1px solid silver;
    border-radius: 0.25rem;
    padding: 0;
    /* background-color: #d7fcf8; */
    /* min-width: 50%; */
    /* min-height: 50%; */
}

.result-modal::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.result-modal .header {
    background-color: var(--color-dark);
    text-align: center;
    color: var(--color-light);
    padding: 0.5rem 1rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.result-modal h2 {
    font-family: 'Handjet', monospace;
    margin: 0 0 0.25rem 0;
    font-size: 3rem;
    text-transform: uppercase;
}

.result-modal progress {
    width: 100%;
    height: 1.5rem;
    margin: 0;
    accent-color: #46cebe;
}

.result-modal p {
    margin: 0.5rem 0;
    font-size: 1.2rem;
}

.result-modal p strong {
    color: #10655b;
}


.modalBody {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* min-height: 50vh; */
}


.backdrop-not-used {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Ensure it is on top of other content */
}

button.modalClose {
    font-size: 1.5rem;
    font-weight: normal;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: var(--color-dark);
    border: none;
}

button.modalClose:hover {
    font-weight: bold;
    cursor: pointer;

}