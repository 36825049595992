button {
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-dark);
    border-radius: 4px;
    background: var(--color-dark);
    color: white;
    cursor: pointer;
    width: fit-content;
    margin-top: 0.5rem;
}

button:hover {
    font-weight: bold;
}


button.cancel {
    background-color: var(--color-light);
    border: 1px solid var(--color-light);
    color: var(--color-dark);
}

button.cancel:hover {
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
}


button.delete {
    background-color: var(--color-light);
    border: 1px solid var(--color-light);
    color: red;
}

button.delete:hover {
    /* background: red; */
    /* color: white; */
    border: 1px solid red;
}

button.tab {
    background-color: var(--color-light);
    border: none;
    border-radius: 0;
    border-bottom: 2px solid silver;
    color: var(--color-blue);
    margin: 0 5px;
}

button.light {
    background: white;
    color: var(--color-dark);
}